import React, { FC } from "react";
import Script from "next/script";
import { useAppState } from "hooks/AppContext";

/**
 * Renders Raptive ads
 * If the URL parameter '?hide-ads' is present, ads will not be displayed.
 */
const Raptive: FC = () => (
    <>
        <Script
            strategy="afterInteractive"
            id="Raptive-script"
            key="Raptive-script"
            data-no-optimize="1"
            data-cfasync="false"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: `
                (function(w, d) {
                    w.adthrive = w.adthrive || {};
                    w.adthrive.cmd = w.
                    adthrive.cmd || [];
                    w.adthrive.plugin = 'adthrive-ads-manual';
                    w.adthrive.host = 'ads.adthrive.com';var s = d.createElement('script');
                    s.async = true;
                    s.referrerpolicy='no-referrer-when-downgrade';
                    s.src = 'https://' + w.adthrive.host + '/sites/677bfa479756a83cfd19aad9/ads.min.js?referrer=' + w.encodeURIComponent(w.location.href) + '&cb=' + (Math.floor(Math.random() * 100) + 1);
                    d.head.appendChild(s);
                })(window, document);
            `
        }} />
        <Script
            strategy="lazyOnload"
            id="Raptive-Recovery-script"
            key="Raptive-Recovery-script"
            src="https://btloader.com/tag?o=5698917485248512&upapi=true&domain=themuse.com"
        />
        <Script
            strategy="lazyOnload"
            id="Raptive-Recovery-block"
            key="Raptive-Recovery-block"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: `!function(){"use strict";var e;e=document,function(){var t,n;function r(){var t=e.createElement("script");t.src="https://cafemedia-com.videoplayerhub.com/galleryplayer.js",e.head.appendChild(t)}function a(){var t=e.cookie.match("(^|[^;]+)\s*__adblocker\s*=\s*([^;]+)");return t&&t.pop()}function c(){clearInterval(n)}return{init:function(){var e;"true"===(t=a())?r():(e=0,n=setInterval((function(){100!==e&&"false" !== t || c(), "true" === t && (r(), c()), t = a(), e++}), 50))}}}().init()}();`
        }} />
    </>
);
Raptive.displayName = "Raptive";

interface AdsProps {
    hasAds: boolean;
}

/**
 * Renders MediaVine or Raptive ads.
 * If the URL parameter '?hide-ads' is present, ads will not be displayed.
 */
export const Ads: FC<AdsProps> = ({ hasAds }) => {
    const { pageMetaData: { pathName } } = useAppState();
    const isBlocked = pathName.includes("hide-ads");
    return !hasAds || isBlocked ? null : <Raptive />;
};
Ads.displayName = "Ads";
